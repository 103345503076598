import * as React from 'react'
import {
  Container,
  Box,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import ContactForm from '../components/contact'
import Seo from '../components/seo'

const IndexPage = () => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Layout>
      <Seo
        title="Press enquiries | Mud Foods"
        description="Contact us directly for any media related enquiries"
      />
      <Container py={20}>
        <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
          <Box>
            <Stack spacing={4}>
              <Heading as="h1" color={headingColor}>
                Press enquiries
              </Heading>
              <Text>
                For any media enquiries, please contact Christian at Mud Foods
              </Text>
              <Heading as="h5" size="sm">
                By Phone
              </Heading>
              <Text>
                <a href="tel:+441730 815435">+44 (0) 1730 815435</a>
              </Text>
              <Heading as="h5" size="sm">
                By Email
              </Heading>
              <Text>
                <a href="mailto:info@mudfoods.com">info@mudfoods.com</a>
              </Text>
              <Heading as="h5" size="sm">
                By Post
              </Heading>
              <Text>Mud Foods Ltd, Wedglen Park, Midhurst, GU29 9RE</Text>
            </Stack>
          </Box>
          <Box p={0} data-name="product-image-box">
            <Heading as="h3" size="md" mb={2}>
              By form
            </Heading>

            <ContactForm business telephone />
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export default IndexPage
